import { Injectable } from '@angular/core';
import { interval, Observable, Subject, Subscription, throttle } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserIdleServiceService {
  private idleSubject = new Subject<boolean>();
  private timeout = 600;
  private lastUserActivity?: Date
  private idleCheckInterval = 10
  private idleSubscription?: Subscription

  constructor() { 
    this.resetTimer()
    this.startWatching();
  }

  get idleState(): Observable<boolean> {
    return this.idleSubject.asObservable();
  }

  private startWatching() {
    this.idleSubscription = interval(this.idleCheckInterval * 1000)
        .pipe(throttle(() => interval(1000)))
        .subscribe(() => {
          const now = new Date()
          if (
            now.getTime() - this.lastUserActivity?.getTime() !> this.timeout * 3000
          ) {
            this.idleSubject.next(true)
          }
      });
  }

  resetTimer() {
    this.lastUserActivity = new Date()
    this.idleSubject.next(false)
  }

  stopWatching() {
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe()
    }
  }
}
