<div class="row">
  <div class="col-sm-12">
    <!-- Hover table card start -->
    <app-card [title]="''" [classHeader]="true" [blockClass]="'table-border-style'">
      <form (ngSubmit)="submitRegistrationForm()" [formGroup]="registrationForm">
        <div class="row">
          <div class="col-12">
            <h4>Registered Details</h4>
            <hr />
          </div>
          <div class="form-group col-lg-4 col-md-3 col-sm-12">
            <label class="control-label">Registered Name</label>
            <input type="email" class="form-control rounded" formControlName="reg_name" />
          </div>

          <div class="form-group col-lg-4 col-md-3 col-sm-12">
            <label for="exampleFormControlInput1" class="control-label">Registration No(optional)</label>
            <input type="email" class="form-control rounded" id="exampleFormControlInput1"
              formControlName="reg_no" />
          </div>

          <div class="form-group col-lg-4 col-md-3 col-sm-12">
            <label for="exampleFormControlInput1" class="control-label">Primary Conatact No</label>
            <input type="email" class="form-control" id="exampleFormControlInput1" formControlName="primary_conatct_no" />
          </div>

          <div class="form-group col-lg-3 col-md-3 col-sm-12">
            <label for="exampleFormControlInput1" class="control-label">Primary Email Id</label>
            <input type="email" class="form-control" id="exampleFormControlInput1"
              formControlName="primary_email_id" />
          </div>

          <div class="form-group col-lg-3 col-md-3 col-sm-12">
            <label for="exampleFormControlInput1" class="control-label">Contact Person Name</label>
            <input type="email" class="form-control rounded" id="exampleFormControlInput1" 
              formControlName="contact_person_name" />
          </div>

          <div class="form-group col-lg-3 col-md-3 col-sm-12">
            <label for="exampleFormControlInput1" class="control-label">Company Type</label>
            <select class="form-select rounded" aria-label="select company" formControlName="company_type">
              <option value="product">Product</option>
              <option value="service">Service</option>
            </select>
          </div>

          <div class="form-group col-lg-3 col-md-3 col-sm-12">
            <label for="exampleFormControlInput1" class="control-label">Select State</label>
            <select class="form-select rounded" aria-label="select company" formControlName="company_state">
              <option value="{{ itemSt.state_gst_code + '-' + itemSt.state_name }}" *ngFor="let itemSt of stateList">
                {{
                  itemSt.state_gst_code.padStart(2, 0) +
                    " - " +
                    itemSt.state_name
                }}
              </option>
            </select>
          </div>

          <div class="form-group col-12">
            <label for="exampleFormControlInput1" class="control-label">Registered Address</label>
            <textarea class="form-control rounded" aria-label="Default select example" rows="4"
              formControlName="reg_address"></textarea>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mt-3">
            <h4>GST Details</h4>
            <hr />
          </div>
          <div class="form-group col-lg-4 col-md-4 col-sm-12">
            <label class="control-label">Is GST Registered</label>
            <select class="form-select" aria-label="select gst" formControlName="isGST" (ngModelChange)="isGSTChange()">
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </div>

          <div class="form-group col-lg-4 col-md-4 col-sm-12">
            <label for="exampleFormControlInput1" class="control-label">GST Number</label>
            <input type="email" class="form-control rounded" id="exampleFormControlInput1"
              [readonly]="isGST == 0" formControlName="gstNumber" />
          </div>

          <div class="form-group col-lg-4 col-md-4 col-sm-12">
            <label for="exampleFormControlInput1" class="control-label">GST Address</label>
            <input type="email" class="form-control rounded" id="exampleFormControlInput1"
              [readonly]="isGST == 0" formControlName="gstAddress" />
          </div>
        </div>

        <div class="row">
          <div class="col-12 mt-3">
            <h4>Display Details</h4>
            <hr />
          </div>
          <div class="form-floating col-lg-4 col-md-3 col-sm-12">
            <label class="control-label">Display Name</label>
            <input type="email" class="form-control rounded" formControlName="display_name" />
          </div>

          <div class="form-group col-lg-4 col-md-3 col-sm-12">
            <label for="exampleFormControlInput1" class="control-label">Support Email Id</label>
            <input type="email" class="form-control rounded" id="exampleFormControlInput1" 
              formControlName="support_email_id" />
          </div>

          <div class="form-group col-lg-4 col-md-3 col-sm-12">
            <label for="exampleFormControlInput1" class="control-label">Support Conatact No</label>
            <input type="email" class="form-control" id="exampleFormControlInput1" 
              formControlName="support_contact_no" />
          </div>

          <div class="form-group col-12">
            <label for="exampleFormControlInput1" class="control-label">Display Address</label>
            <textarea class="form-control rounded" aria-label="Default select example" rows="4"
              formControlName="display_address"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ngb-alert [dismissible]="true" [animation]="true" type="danger" class="col-xl-8 col-md-8 col-12 float-left"
              *ngIf="errorMessage">
              {{ errorMessage }}
            </ngb-alert>

            <button type="submit" class="btn btn-dark float-right rounded mb-5 float-right">
              Add Company
            </button>
          </div>
        </div>
      </form>
    </app-card>
    <!-- Hover table card end -->
  </div>
</div>
