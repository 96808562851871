import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonFunction } from 'src/app/shared/common/common-function';
import { MenuItems } from 'src/app/shared/menu-items/menu-items';
import { UserIdleServiceService } from 'src/app/user-idle-service.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  userName: string;
  accountType: string;
  public config: any;
  selectedMenu = "";
  idleService = inject(UserIdleServiceService)
  

  constructor(private router: Router, public menuItems: MenuItems, private activatedRoute: ActivatedRoute) {
    
  }

  ngOnInit() {
    this.idleService.idleState.subscribe((isIdle) => {
      if (isIdle) {
        this.logout()
      }
    })
    this.router.events.subscribe(event => {
      this.selectedMenu = this.activatedRoute.snapshot.children[0].data['state'];
    })
    this.userDetails();
  }

  userDetails() {
    let userDetials = JSON.parse(CommonFunction.dycryptData(localStorage.getItem('info')));
    this.userName = userDetials.name
    let role = userDetials.userRole.replace(/([a-z])([A-Z])/g, '$1 $2');

    if (role === "Super Company") {
      this.accountType = "SC"
    } else if (role === "Company") {
      this.accountType = "HQ"
    } else {
      this.accountType = "Client"
    }

  }

  userActionDetect() {
    this.idleService.resetTimer()
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/'], { replaceUrl: true });
  }

  ngOnDestroy(): void {
    this.idleService.stopWatching()
  }

}
