import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonFunction } from 'src/app/shared/common/common-function';

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  baseUrl: string = CommonFunction.getBaseUrl();

  constructor(private httpClient: HttpClient) { }


  createPlan(data: any) {
    return this.httpClient.post(this.baseUrl + "create-plan", data, { headers: CommonFunction.getToken() });
  }

  planList(data: any) {
    return this.httpClient.post(this.baseUrl + "plan-list", data, { headers: CommonFunction.getToken() });
  }
}
