<div class="pcoded" (mousemove)="userActionDetect()" (click)="userActionDetect()" (keypress)="userActionDetect()">
  <div class="pcoded-overlay-box"></div>
  <div class="pcoded-container navbar-wrapper">
    <nav class="navbar header-navbar pcoded-header bg-dark sticky-top">
      <div class="navbar-wrapper">
        <div class="navbar-logo">
          <a [routerLink]="['/']" class="bg-white p-1 rounded">
            <img class="img-fluid" src="assets/images/logo/logotp.png" alt="Theme-Logo" width="50" />
          </a>
          <span style="width: max-content">BPAHR <small>{{ accountType }}</small>
          </span>
        </div>
        <div style="
            float: right;
            cursor: pointer;
            margin: 0px 50px 0px 0px;
            position: absolute;
            top: 50%;
            right: 0;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          ">
          <span style="color: white; text-decoration: none" data-toggle="tooltip" [title]="userName"
            class="material-symbols-outlined">
            person
          </span>
        </div>

        <div style="
            float: right;
            cursor: pointer;
            margin: 0px 10px 0px 0px;
            position: absolute;
            top: 50%;
            right: 0;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          ">
          <a (click)="logout()" style="color: white; text-decoration: none" data-toggle="tooltip" title="Logout"
            class="material-symbols-outlined">
            logout
          </a>
        </div>
      </div>
    </nav>
    <div class="pcoded-main-container">
      <div class="pcoded-wrapper">
        <div class="pcoded-content">
          <nav id="main_navbar" class="pcoded-navbar" style="z-index: 1">
            <div class="top_menu p-0">
              <a *ngFor="let item of menuItems.getAll(); let i = index" [routerLink]="item.state"
                [class]="selectedMenu === item.state ? 'active' : ''">
                <span class="material-symbols-outlined"> {{ item.icon }} </span>
                <h5 [innerHTML]="item.name"></h5>
              </a>
            </div>
          </nav>

          <div class="pcoded-inner-content" style="position: fixed">
            <div class="main-body">
              <div class="page-wrapper">
                <div class="page-body">
                  <router-outlet></router-outlet>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>