import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { CommonFunction } from '../../../shared/common/common-function';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl: string = CommonFunction.getBaseUrl();
  errorMessage = new EventEmitter<string>();
  successMessage = new EventEmitter<string>();

  constructor(private httpClient: HttpClient, private router: Router) { }

  isUserAuthenticated() {
    let data = localStorage.getItem("token");
    let info = localStorage.getItem("info");
    if (data && data != "" && info && info != "") {
      return true;
    }
    return false;
  }

  isSuperCompanyAuthenticated() {
    let data = JSON.parse(CommonFunction.dycryptData(localStorage.getItem("info")));
    if (data?.userRole == "SuperCompany") {
      return true;
    }
    return false;
  }

  loginUser() {
    return JSON.parse(CommonFunction.dycryptData(localStorage.getItem("info")));
  }

  userLogin(data: any, prevRoute: any) {
    return this.httpClient.post(this.baseUrl + "login", data, { headers: CommonFunction.getAuthToken() }).subscribe((res: any) => {
      if (res && res.statusCode === 201) {
        this.errorMessage.emit(res.message);
      } else if (res && res.statusCode === 200) {
        let userName = res.name;
        let userEmail = res.email;
        let userMobile = res.mobile_no;
        let token = res.token;
        let userRole = res.user_role;
        let userSubRole = res.user_sub_role;
        let companyName = res.company_name;

        let data = {
          "name": userName,
          "emailId": userEmail,
          "mobileNo": userMobile,
          "userRole": userRole,
          "subRole": userSubRole,
          "companyName" : companyName + " (HQ)"
        }
        localStorage.setItem('token', token);
        localStorage.setItem('info', CommonFunction.encryptData(JSON.stringify(data)));
        localStorage.setItem('rights', CommonFunction.encryptData(JSON.stringify(res.user_rights)));
        if (prevRoute) {
          this.router.navigateByUrl(prevRoute);
        } else {
          this.router.navigate(['/dashboard'], { replaceUrl: true });
        }
      }
    });
  }
}
