import { Component } from '@angular/core';

@Component({
  selector: 'app-access-denied-component',
  templateUrl: './access-denied-component.component.html',
  styleUrl: './access-denied-component.component.scss'
})
export class AccessDeniedComponent {

}
