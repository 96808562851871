import { HttpHeaders } from "@angular/common/http";
import * as CryptoJS from 'crypto-js';
import { environment } from "src/environments/environment";

export class CommonFunction {

    static getBaseUrl(): string {
        return environment.base_url;
    }

    static getAuthToken() {
        const date = new Date();
        const encryptStr = date.toLocaleString('en-GB', { timeZone: 'Asia/Kolkata', localeMatcher: "best fit" }) + '!carApi2023';
        const strLen = encryptStr.length;
        let output: string = '';
        for (let i = 0; i < strLen; i++) {
            const asciiVal = encryptStr.charCodeAt(i) + 2;
            output += String.fromCharCode(asciiVal);
        }
        const httpHeaders: HttpHeaders = new HttpHeaders({
            Authorization: 'Bearer ' + output,
        });

        return httpHeaders;
    }

    static getToken() {
        const date = new Date();
        const encryptStr = date.toLocaleString('en-GB', { timeZone: 'Asia/Kolkata', localeMatcher: "best fit" }) + '!carApi2023';
        const strLen = encryptStr.length;
        let output: string = '';
        for (let i = 0; i < strLen; i++) {
            const asciiVal = encryptStr.charCodeAt(i) + 2;
            output += String.fromCharCode(asciiVal);
        }
        let httpHeaders: HttpHeaders = new HttpHeaders({
            Authorization: 'Bearer ' + output,
            token: localStorage.getItem('token')
        });
        return httpHeaders;
    }

    static encryptData(data: any) {
        let encryptData = CryptoJS.AES.encrypt(data, "SGV5TXlBcHBGb3JBY2NvdW50aW5n").toString();
        return encryptData;
    }

    static dycryptData(data: any) {
        let decryptedMessage = CryptoJS.AES.decrypt(data, "SGV5TXlBcHBGb3JBY2NvdW50aW5n").toString(CryptoJS.enc.Utf8);
        return decryptedMessage;
    }

    static checkUserRights(rightStr: string) {
        let data = JSON.parse(CommonFunction.dycryptData(localStorage.getItem('rights')));
        return data.indexOf(rightStr) > -1
    }


    static checkUserRole() {
        let data = JSON.parse(CommonFunction.dycryptData(localStorage.getItem('info')));
        return data.userRole;
    }
}
