import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { CommonFunction } from '../shared/common/common-function';
import { inject } from '@angular/core';

export const permissionGuard: CanActivateFn = (route, state) => {
  const router = inject(Router)

  if(route.data.permission && CommonFunction.checkUserRights(route.data.permission)) {
    return true;
  } else {
    router.navigate(['/access-denied'], { replaceUrl: true });
    return false;
  }
};