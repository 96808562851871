
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { NgModule } from '@angular/core';
import { AuthGuard } from './guard/auth.guard';
import { CompanyRegistrationComponent } from './pages/company-manager/company-registration/company-registration.component';
import { CreatePlanComponent } from './pages/plan-manager/create-plan/create-plan.component';
import { permissionGuard } from './guard/permission-guard.guard';
import { AccessDeniedComponent } from './pages/access-denied-component/access-denied-component.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        data: { state: "dashboard"},
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'access-denied',
        data: { state: "dashboard" },
        component: AccessDeniedComponent
      },
      {
        path: 'company-manager',
        loadChildren: () => import('./pages/company-manager/company-manager.module').then(m => m.CompanyManagerModule)
      },
      {
        path: 'company-registration',
        component: CompanyRegistrationComponent,
        data: {
          breadcrumb: 'Company Registration',
          icon: 'icofont-layout bg-c-blue',
          breadcrumb_caption: 'Fill the details to register a new company',
          status: true,
          userRights: 'companyRegistration'
        }
      },
      {
        path: 'plan-manager',
        loadChildren: () => import('./pages/plan-manager/plan-manager.module').then(m => m.PlanManagerModule)
      },
      {
        path: 'create-plan',
        component: CreatePlanComponent,
        data: {
          breadcrumb: 'Create Plan',
          icon: 'icofont-layout bg-c-blue',
          breadcrumb_caption: 'Create or add a new package',
          status: true,
          userRights: 'assignRights'
        },
      },
      {
        path: 'licence-manager',
        loadChildren: () => import('./pages/licence-manager/licence-manager.module').then(m => m.LicenceManagerModule)
      },
      {
        path: 'nominal-manager',
        canActivate: [permissionGuard],
        canActivateChild: [permissionGuard],
        data: { 'state': 'nominal-manager', permission: 'nominal_manager' },
        loadChildren: () => import('./pages/nominal-manager/nominal-manager.module').then(m => m.NominalManagerModule)
      },
      {
        path: 'state-manager',
        data: { 'state': 'state-manager' },
        loadChildren: () => import('./pages/state-manager/state-manager.module').then(m => m.StateManagerModule)
      },
      {
        path: 'project-manager',
        canActivate: [permissionGuard],
        canActivateChild: [permissionGuard],
        data: { 'state': 'project-manager', permission: 'project_manager' },
        loadChildren: () => import('./pages/builder/project-sites-manager/project-sites-manager.module').then(m => m.ProjectSitesManagerModule)
      },
      {
        path: 'inventory-manager',
        canActivate: [permissionGuard],
        canActivateChild: [permissionGuard],
        data: { 'state': 'inventory-manager', permission: 'inventory_manager' },
        loadChildren: () => import('./pages/builder/inventory-manager/inventory-manager.module').then(m => m.InventoryManagerModule)
      },
      {
        path: 'stock-manager',
        data: { 'state': 'stock-manager' },
        loadChildren: () => import('./pages/builder/stock-manager/stock-manager.module').then(m => m.StockManagerModule)
      },
      {
        path: 'supplier-manager',
        canActivate: [permissionGuard],
        canActivateChild: [permissionGuard],
        data: { 'state': 'supplier-manager', permission: 'supplier_manager'},
        loadChildren: () => import('./pages/builder/supplier-manager/supplier-manager.module').then(m => m.SupplierManagerModule)
      },
      {
        path: 'material-request',
        data: { 'state': 'material-request' },
        loadChildren: () => import('./pages/builder/material-request/material-request.module').then(m => m.MaterialRequestModule)
      },
      {
        path: 'order-manager',
        canActivate: [permissionGuard],
        canActivateChild: [permissionGuard],
        data: { 'state': 'order-manager', permission: 'order_manager'},
        loadChildren: () => import('./pages/builder/order-manager/order-manager.module').then(m => m.OrderManagerModule)
      },
      {
        path: 'grn-manager',
        canActivate: [permissionGuard],
        canActivateChild: [permissionGuard],
        data: { 'state': 'grn-manager', permission: 'purchase_manager' },
        loadChildren: () => import('./pages/builder/purchase-manager/grn-manager.module').then(m => m.GRNManagerModule)
      },
      {
        path: 'hr-manager',
        canActivate: [permissionGuard],
        canActivateChild: [permissionGuard],
        data: { 'state': 'hr-manager', permission: 'hr_manager' },
        loadChildren: () => import('./pages/builder/hr-manager/hr-manager.module').then(m => m.HRManagerModule)
      },
      { 
        path: 'asset-manager',
        canActivate: [permissionGuard],
        canActivateChild: [permissionGuard],
        data: { 'state': 'asset-manager', permission: 'assets_manager'},
        loadChildren: () => import('./pages/asset-manager/asset-manager.module').then(m => m.AssetManagerModule) 
      },
      { 
        path: 'banking-manager',
        canActivate: [permissionGuard],
        canActivateChild: [permissionGuard],
        data: { 'state': 'banking-manager', permission: 'banking_manager'},
        loadChildren: () => import('./pages/banking-manager/banking-manager.module').then(m => m.BankingManagerModule) 
      },
      { 
        path: 'crm-manager', 
        data: {'state': 'crm-manager'},
        loadChildren: () => import('./pages/crm-manager/crm-manager.module').then(m => m.CrmManagerModule) 
      },
      { 
        path: 'report-manager', 
        canActivate: [permissionGuard],
        canActivateChild: [permissionGuard],
        data: { 'state': 'report-manager', permission: 'report_manager' },
        loadChildren: () => import('./pages/report-manager/report-manager.module').then(m => m.ReportManagerModule) 
      },
      { 
        path: 'setting-manager', 
        canActivate: [permissionGuard],
        canActivateChild: [permissionGuard],
        data: { 'state': 'setting-manager', permission: 'company_setup' },
        loadChildren: () => import('./pages/setting-manager/setting-manager.module').then(m => m.SettingManagerModule) 
      },
      {
        path: 'sales-manager',
        canActivate: [permissionGuard],
        canActivateChild: [permissionGuard],
        data: { 'state': 'sales-manager', permission: 'sales_manager' },
        loadChildren: () => import('./pages/sales-manager/sales-manager.module').then(m => m.SalesManagerModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
