import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { url } from 'inspector';
import { CommonFunction } from 'src/app/shared/common/common-function';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private httpClient: HttpClient) { }

  createCompany(data: any) {
    return this.httpClient.post(CommonFunction.getBaseUrl() + "create-company", data, { headers: CommonFunction.getToken() });
  }

  getCompnayList(data: any) {
    return this.httpClient.post(CommonFunction.getBaseUrl() + "company-list", data, { headers: CommonFunction.getToken() });
  }

  getActiveCompanyList() {
    return this.httpClient.post(CommonFunction.getBaseUrl() + "active-company-list", {}, { headers: CommonFunction.getToken() });
  }

  accountGroupList() {
    return this.httpClient.get(CommonFunction.getBaseUrl() + "account-group", { headers: CommonFunction.getToken() });
  }

  getLicenceDetails(data: any) {
    return this.httpClient.post(CommonFunction.getBaseUrl() + "company-licence-and-package", data, { headers: CommonFunction.getToken() });
  }

  buyLicence(data: any) {
    return this.httpClient.post(CommonFunction.getBaseUrl() + "buy-licence", data, { headers: CommonFunction.getToken() });
  }
}
