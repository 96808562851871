import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonFunction } from 'src/app/shared/common/common-function';

@Injectable({
  providedIn: 'root'
})
export class StateManagerService {

  constructor(private httpClient: HttpClient) { }

  getServiceList() {
    return this.httpClient.get(CommonFunction.getBaseUrl() + "state-codes", { headers: CommonFunction.getToken() });
  }
}
