import { Injectable } from '@angular/core';
import { CommonFunction } from '../common/common-function';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  icon?: string;
  userRight?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
  userRight?: string
}

export interface Menu {
  label: string;
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  icon: string;
  badge?: BadgeItem[];
  userRight?: string,
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    label: 'Dashboard',
    state: 'dashboard',
    short_label: 'D',
    name: 'Dashboard',
    icon: 'empty_dashboard'
  },
  {
    label: 'Inventory<br/>Manager',
    state: 'inventory-manager',
    short_label: 'IM',
    name: 'Inventory<br/>Manager',
    icon: 'inventory',
    userRight: 'inventory_manager',
    children: [
      {
        state: 'all-inventory',
        name: 'All<br/>Inventory',
        icon: 'inventory',
        userRight: 'all_inventory'
      },
      {
        state: 'create-inventory',
        name: 'Create<br/>Inventory',
        icon: 'contract_edit',
        userRight: 'create_inventory'
      },
      {
        state: 'pos-services',
        name: 'POS<br/>Services',
        icon: 'sell',
        userRight: 'pos_services'
      },
      {
        state: 'stock-adjustment',
        name: 'Stock<br/>Adjustment',
        icon: 'inventory_2',
        userRight: 'stock_adjustment'
      },
      {
        state: 'view-stock',
        name: 'View<br/>Stock Card',
        icon: 'tv_options_edit_channels',
        userRight: 'stock_card'
      }
    ]
  },
  {
    label: 'Project<br/>Manager',
    state: 'project-manager',
    short_label: 'IM',
    name: 'Project<br/>Manager',
    icon: 'apartment',
    userRight: 'project_manager',
    children: [
      {
        state: './',
        name: 'All<br/>Project List',
        icon: 'apartment'
      },
      {
        state: 'projects-create',
        name: 'Register<br/>Project',
        icon: 'domain_add'
      },
      {
        state: 'all-wing',
        name: 'Wing<br/>Manager',
        icon: 'location_city'
      },
      {
        state: 'all-floor',
        name: 'Floor<br/>Manager',
        icon: 'corporate_fare'
      },
      {
        state: 'all-flat',
        name: 'Flat<br/>Manager',
        icon: 'meeting_room'
      }
    ]
  },
  {
    label: 'Supplier<br/>Manager',
    state: 'supplier-manager',
    short_label: 'IM',
    name: 'Supplier<br/>Manager',
    icon: 'person_check',
    userRight: 'supplier_manager',
    children: [
      {
        state: 'all_supplier',
        name: 'All<br/>Suppliers',
        icon: 'demography',
        userRight: 'all_supplier'
      },
      {
        state: 'create-supplier',
        name: 'Create<br/>Supplier',
        icon: 'group_add',
        userRight: 'create_supplier'
      }
    ]
  },
  {
    label: 'Material Request<br/>Manager',
    state: 'material-request',
    short_label: 'IM',
    name: 'Material<br/>Request',
    icon: 'garden_cart',
    userRight: 'material_manager',
    children: [
      {
        state: './',
        name: 'All<br/>Request',
        icon: 'garden_cart'
      }
    ]
  },
  {
    label: 'Order<br/>Manager',
    state: 'order-manager',
    short_label: 'IM',
    name: 'Order<br/>Manager',
    icon: 'shopping_cart_checkout',
    userRight: 'order_manager',
    children: [
      {
        state: 'all-order',
        name: 'All<br/>Orders',
        icon: 'shopping_cart_checkout',
        userRight: 'all_order'
      },
      {
        state: 'create-order',
        name: 'Create<br/>Order',
        icon: 'deployed_code',
        userRight: 'create_order'
      }
    ]
  },
  {
    label: 'Purchase<br/>Manager',
    state: 'grn-manager',
    short_label: 'IM',
    name: 'Purchase<br/>Manager',
    icon: 'shopping_bag',
    userRight: 'purchase_manager',
    children: [
      {
        state: 'grn-list',
        name: 'GRN<br/>List',
        icon: 'format_list_bulleted',
        userRight: 'grn_list'
      },
      {
        state: 'create-grn',
        name: 'Raised<br/>GRN',
        icon: 'videogame_asset',
        userRight: 'raised_grn'
      },
      {
        state: 'invoice',
        name: 'Post<br/>Invoice',
        icon: 'inventory',
        userRight: 'post_purchase_invoice'
      },
      {
        state: 'statement',
        name: 'Supplier<br/>Statement',
        icon: 'summarize',
        userRight: 'supplier_statement'
      },
      {
        state: 'payment-summary',
        name: 'Payment<br/>Summary',
        icon: 'shopping_cart',
        userRight: 'supplier_payment_summary'
      }
    ]
  },
  {
    label: 'Asset<br/>Manager',
    state: 'asset-manager',
    short_label: 'IM',
    name: 'Asset<br/>Manager',
    icon: 'videogame_asset',
    userRight: 'assets_manager',
    children: [
      {
        state: 'all-assets',
        name: 'Asset<br/>List',
        icon: 'videogame_asset',
        userRight: 'all_assets',
      },
      {
        state: 'stockToAsset',
        name: 'Stock<br/>To Asset',
        icon: 'move_up',
        userRight: 'stock_to_assets',
      }
    ]
  },
  {
    label: 'Banking<br/>Manager',
    state: 'banking-manager',
    short_label: 'IM',
    name: 'Banking<br/>Manager',
    icon: 'account_balance',
    userRight: 'banking_manager',
    children: [
      {
        state: 'chequebook',
        name: 'All<br/>Cheque Book',
        icon: 'checkbook',
        userRight: 'cheque_books'
      },
      {
        state: 'petty-cash',
        name: 'Petty<br/>CashBook',
        icon: 'payments',
        userRight: 'petty_cash'
      },
    ]
  },
  {
    label: 'Nominal<br/>Manager',
    state: 'nominal-manager',
    short_label: 'IM',
    name: 'Nominal<br/>Manager',
    icon: 'account_balance_wallet',
    userRight: 'nominal_manager',
    children: [      
      {
        state: 'nominal-account',
        name: 'Nominal<br/>Account',
        icon: 'manage_accounts',
        userRight: 'all_nominal_account'
      },
      {
        state: 'nominal-ledger',
        name: 'Nominal<br/>Ledger',
        icon: 'supervisor_account',
        userRight: 'nominal_ledger'
      },
      {
        state: 'nominal-account-subgroup',
        name: 'Nominal<br/>Sub Group',
        icon: 'group',
        userRight: 'nominal_sub_group'
      }
    ]
  },
  {
    label: 'HR<br/>Manager',
    state: 'hr-manager',
    short_label: 'IM',
    name: 'HR<br/>Manager',
    icon: 'badge',
    userRight: 'hr_manager',
    children: [
      {
        state: 'all-employee',
        name: 'View All<br/>Employees',
        icon: 'groups',
        userRight: 'all_employee'
      },
      {
        state: 'create-employee',
        name: 'Create<br/>Employee',
        icon: 'person_add',
        userRight: 'create_employee'
      },
      {
        state: 'holiday-manager',
        name: 'Holiday<br/>Manager',
        icon: 'holiday_village',
      },
      {
        state: 'leave-list',
        name: 'Leave\'s<br/>List',
        icon: 'calendar_clock',
      },
      {
        state: 'attendance-manager',
        name: 'Attendance<br/>Manager',
        icon: 'perm_contact_calendar',
      }
    ]
  },
  {
    label: 'CRM<br/>Manager',
    state: 'crm-manager',
    short_label: 'IM',
    name: 'CRM<br/>Manager',
    icon: 'leaderboard',
    userRight: 'crm_manager',
    children: [
      {
        state: './',
        name: 'Enquiry\'s<br/>List',
        icon: 'search'
      },
      {
        state: 'quot-list',
        name: 'Quotation<br/>List',
        icon: 'receipt_long'
      }
    ]
  },
  {
    label: 'Sales Manager',
    state: 'sales-manager',
    short_label: 'D',
    name: 'Sales<br/>Manager',
    icon: 'real_estate_agent',
    userRight: 'sales_manager',
    children: [
      {
        state: 'booking-list',
        name: 'Booking<br/>List',
        icon: 'real_estate_agent',
      },
      {
        state: 'new-booking',
        name: 'New<br/>Booking',
        icon: 'point_of_sale'
      },
      {
        state: 'do-service-sale',
        name: 'Do<br/>Service Sale',
        icon: 'point_of_sale',
        userRight: 'do_service_sales',
      },
      {
        state: 'service-sales',
        name: 'Service<br/>Sales',
        icon: 'contract',
        userRight: 'all_service_sales',
      },
      {
        state: 'pending-payment',
        name: 'Pending<br/>Payment',
        icon: 'credit_card_clock'
      },
      {
        state: 'pending-sales-payment',
        name: 'Sales<br/>Payment',
        icon: 'credit_card_clock',
        userRight: 'pending_sales_payment',
      },
    ]
  },
  {
    label: 'Report<br/>Manager',
    state: 'report-manager',
    short_label: 'IM',
    name: 'Report<br/>Manager',
    icon: 'folder_open',
    userRight: 'report_manager',
    children: [
      {
        state: 'trail-balance',
        name: 'Trail<br/>Balance',
        icon: 'menu_book',
        userRight: 'trail_balance'
      },
      {
        state: 'profit-loss',
        name: 'Profit &<br/>Loss Sheet',
        icon: 'data_exploration',
        userRight: 'profit_loss_sheet'
      },
      {
        state: 'balance-sheet',
        name: 'Balance<br/>Sheet',
        icon: 'heap_snapshot_large',
        userRight: 'balance_sheet'
      }
    ]
  },
  {
    label: 'Company<br/>Setting',
    state: 'setting-manager',
    name: 'Company<br/>Setting',
    icon: 'settings',
    userRight: 'company_setup',
    children: [
      {
        state: 'code-setup',
        name: 'Code<br/>Setup',
        icon: 'settings',
        userRight: 'code_setup'
      },
      {
        state: 'sales-setup',
        name: 'Sales<br/>Setup',
        icon: 'real_estate_agent',
        userRight: 'sales_setup'
      },
      {
        state: 'hr-setup',
        name: 'HR<br/>Setup',
        icon: 'medical_information',
        userRight: 'hr_setup'
      },
      {
        state: 'user-login-access',
        name: 'User Login<br/>& Access',
        icon: 'admin_panel_settings',
        userRight: 'users_setup'
      }
    ]
  },
];

const MENUSUPERCOMPANYITEMS = [
  {
    label: '',
    state: 'dashboard',
    short_label: 'D',
    name: 'Dashboard',
    icon: 'home'
  },
  {
    label: 'Plan\'s Manager',
    state: 'plan-manager',
    short_label: 'PC',
    name: 'Package<br/>Manager',
    icon: 'package_2',
    children: [
      {
        state: 'create-inventory',
        name: 'All Plan'
      },
      {
        state: 'list',
        name: 'Create Plan'
      }
    ]
  },
  {
    label: 'Company Manager',
    state: 'company-registration',
    short_label: 'CR',
    name: 'Company<br/>Manager',
    icon: 'store',
    userRight: 'companyRegistration',
    children: [
      {
        state: 'create-inventory',
        name: 'Company<br/>Registration'
      },
      {
        state: 'list',
        name: 'All<br/>Company'
      }
    ]
  },
  {
    label: 'Access Manager',
    state: 'sup-emp',
    short_label: 'PT',
    name: 'Access<br/>Manager',
    icon: 'fingerprint',
    children: [
      {
        state: 'create-inventory',
        name: 'Company<br/>Rights'
      },
      {
        state: 'list',
        name: 'All<br/>Company'
      }
    ]
  },
  {
    label: 'Nominal Manager',
    state: 'nominal-manager',
    short_label: 'PT',
    name: 'Nominal<br/>Manager',
    icon: 'assured_workload',
    children: [
      {
        state: 'create-inventory',
        name: 'Nominal Account'
      }
    ]
  },
  {
    label: 'Licence Manager',
    state: 'licence-manager',
    short_label: 'LM',
    name: 'Licence<br/>Manager',
    icon: 'license',
    children: [
      {
        state: 'create-inventory',
        name: 'Nominal Account'
      }
    ]
  },
  {
    label: 'State Manager',
    state: 'state-manager',
    short_label: 'LM',
    name: 'State<br/>Manager',
    icon: 'location_city',
  }
];

const MenuForSites = [
  {
    label: 'Dashboard',
    state: 'dashboard',
    short_label: 'D',
    name: 'Dashboard',
    icon: 'empty_dashboard'
  },
  {
    label: 'Stock Manager',
    state: 'stock-manager',
    short_label: 'D',
    name: 'Stock<br/>Manager',
    icon: 'inventory_2',
    children: [
      {
        state: './',
        name: 'Stock<br/>Card',
        icon: 'tv_options_edit_channels'
      },
      {
        state: 'stock-adjustment',
        name: 'Daily<br/>Used Stock',
        icon: 'inventory_2'
      }
    ]
  },
  {
    label: 'Material Manager',
    state: 'material-request',
    short_label: 'D',
    name: 'Material<br/>Manager',
    icon: 'garden_cart',
    children: [
      {
        state: './',
        name: 'All Material<br/>Request',
        icon: 'garden_cart'
      },
      {
        state: 'raised-request',
        name: 'Raised<br/>Request',
        icon: 'add_circle'
      }
    ]
  },
  {
    label: 'Orders Manager',
    state: 'order-manager',
    short_label: 'D',
    name: 'Orders<br/>Manager',
    icon: 'shopping_cart_checkout',
    children: [
      {
        state: './',
        name: 'Orders<br/>List',
        icon: 'shopping_cart_checkout'
      }
    ]
  },
  {
    label: 'Purchase Manager',
    state: 'grn-manager',
    short_label: 'D',
    name: 'Purchase<br/>Manager',
    icon: 'videogame_asset',
    children: [
      {
        state: './',
        name: 'All<br/>GRNs',
        icon: 'format_list_bulleted'
      },
      {
        state: 'create-grn',
        name: 'Raised<br/>GRN(Qty)',
        icon: 'videogame_asset'
      },
      {
        state: 'invoice',
        name: 'Post<br/>Invoice',
        icon: 'inventory'
      }
    ]
  },
  {
    label: 'CRM Manager',
    state: 'crm-manager',
    short_label: 'D',
    name: 'CRM<br/>Manager',
    icon: 'leaderboard',
    children: [
      {
        state: './',
        name: 'All<br/>Enquiry',
        icon: 'support_agent'
      },
      {
        state: 'raised-enquiry',
        name: 'Raised<br/>Enquiry',
        icon: 'add_circle'
      },
      {
        state: 'quot-list',
        name: 'Quotation<br/>List',
        icon: 'receipt_long'
      }
    ]
  },
  {
    label: 'Booking Manager',
    state: 'sales-manager',
    short_label: 'D',
    name: 'Sales<br/>Manager',
    icon: 'real_estate_agent',
    children: [
      {
        state: './',
        name: 'Booking<br/>List',
        icon: 'real_estate_agent'
      }
    ]
  }
]

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    let data = JSON.parse(CommonFunction.dycryptData(localStorage.getItem('info')));
    

    if (data.userRole == 'Company') {
      var tempArr = [];
      MENUITEMS.forEach(element => {
        if(element.state == "dashboard"){
          tempArr.push(element);
        } else if(element.userRight && CommonFunction.checkUserRights(element.userRight) ) {
          tempArr.push(element);
        }
      });
      return tempArr;
    } else if (data.userRole === "SuperCompany") {
      return MENUSUPERCOMPANYITEMS;
    } else if (data.userRole === "ConstructSites") {
      return MenuForSites;
    }
  }

  getSubMenu(state: string): ChildrenItems[] {
    var subMenuData:ChildrenItems[] = [];

    let arr = this.getAll();
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index];
      if (element.state === state) {
        subMenuData = element.children
        break;
      }
    }

    var tempArr = [];
    subMenuData.forEach(elem => {
      if(elem.userRight && CommonFunction.checkUserRights(elem.userRight) ) {
        tempArr.push(elem);
      }
    });

    return tempArr
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
