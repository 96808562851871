import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PackageService } from '../services/package.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-plan',
  templateUrl: './create-plan.component.html',
  styleUrl: './create-plan.component.scss'
})
export class CreatePlanComponent {
  errorMessage: string = "";

  constructor(private packageServvice: PackageService, private totastrService: ToastrService) { }

  planForm = new FormGroup({
    packageName: new FormControl(''),
    noOfUser: new FormControl(''),
    noOfDays: new FormControl(''),
    packageAmount: new FormControl(''),
    planDetails: new FormControl(''),
  });

  submitNewPlan() {
    this.errorMessage = "";
    this.packageServvice.createPlan(this.planForm.value).subscribe((res: any) => {
      if (res.statusCode === 200) {
        this.planForm.reset();
        this.totastrService.success(res.message);
      } else {
        this.errorMessage = res.message;
        this.totastrService.warning(res.message);
      }
    }, err => this.totastrService.error(err.message));
  }
}
