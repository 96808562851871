import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserIdleServiceService } from './user-idle-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Welcome ! BPAHR';


  constructor(private router: Router) { 

  }

  ngOnInit(): void {
    
  }
}
